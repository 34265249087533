import React, { useCallback, useEffect, useReducer, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import Popup from '../../../popup';
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    itemSrc: string,
    binSrc: string,
    message: string,
    value: true | false | null,
    correct: true | false
}

type GameState = {
    items: Item[]
}

const ARROW_SRC = '/assets/tasks/task-304/arrow.png';
const CORRECT_SRC = '/assets/tasks/task-304/correct.png';
const WRONG_SRC = '/assets/tasks/task-304/wrong.png';

const Task304Widget: React.FC<Props> = ({ onComplete }) => {
    const [popup, setPopup] = useState<{ show: boolean, text?: string }>({ show: false })

    const [state, setValue] = useReducer((state: GameState, action: { id: string, value: true | false }) => {
        return {
            ...state,
            items: state.items.map(item => {
                if (item.id === action.id) {
                    return {
                        ...item,
                        value: action.value
                    }
                }

                return { ...item };
            })
        }
    }, {
        items: [
            {
                id: 'i1',
                itemSrc: '/assets/tasks/task-304/item-1.png',
                binSrc: '/assets/tasks/task-304/bin1.png',
                message: 'POPEŁNIŁEŚ BŁĄD!<br /><br />Resztki pochodzenia zwierzęcego – kości, ości, wędliny, ale również sery i twarożki nie nadają się do kompostowania. Powinny trafić do odpadów zmieszanych.',
                value: null,
                correct: false
            },
            {
                id: 'i2',
                itemSrc: '/assets/tasks/task-304/item-6.png',
                binSrc: '/assets/tasks/task-304/bin4.png',
                message: 'POPEŁNIŁEŚ BŁĄD!<br /><br />Karton po soku czy mleku, powinien trafić do żółtego pojemnika na metale i tworzywa sztuczne.',
                value: null,
                correct: false
            },
            {
                id: 'i3',
                itemSrc: '/assets/tasks/task-304/item-4.png',
                binSrc: '/assets/tasks/task-304/bin3.png',
                message: 'POPEŁNIŁEŚ BŁĄD!<br /><br />Zabrudzony, mokry czy tłusty papier nie nadaje się do recyklingu. Powinien trafić do odpadów zmieszanych.',
                value: null,
                correct: false
            },
            {
                id: 'i4',
                itemSrc: '/assets/tasks/task-304/item-5.png',
                binSrc: '/assets/tasks/task-304/bin4.png',
                message: 'POPEŁNIŁEŚ BŁĄD!<br /><br />Tylko szklane opakowania – butelki i słoiki wrzucamy do zielonego worka lub pojemnika . Zbita szklanka, powinna trafić do pojemnika na odpady zmieszane.',
                value: null,
                correct: true
            },
            {
                id: 'i5',
                itemSrc: '/assets/tasks/task-304/item-2.png',
                binSrc: '/assets/tasks/task-304/bin2.png',
                message: 'JESTEŚ PEWNY/A?<br /><br />Spójrz, butelka nie została zgnieciona, to ważna czynność do wykonania przed wrzuceniem do żółtego pojemnika. Wyrzucajmy plastik, nie powietrze!',
                value: null,
                correct: false
            },
            {
                id: 'i6',
                itemSrc: '/assets/tasks/task-304/item-3.png',
                binSrc: '/assets/tasks/task-304/bin1.png',
                message: 'POPEŁNIŁEŚ BŁĄD!<br /><br />Skorupki od jajek stanowią wyjątek wśród odpadów pochodzenia zwierzęcego. Powinniśmy wrzucać je do pojemnika na odpady BIO wraz z obierkami warzyw i owoców,  zwiędłymi kwiatami, czy skoszoną trawą. Pamiętaj jednak, by wyrzucając odpady do brązowego pojemnika, wysypać je z foliowego worka, pozostawiając w koszu jedynie jego zawartość.',
                value: null,
                correct: true
            }
        ]
    })

    const onClick = useCallback((item: Item, value: true | false) => {
        setValue({
            id: item.id,
            value
        });

        if (value !== item.correct) {
            setPopup({
                show: true,
                text: item.message
            })
        }
    }, [setValue, setPopup]);

    useEffect(() => {
        if (onComplete) {
            const complete = state.items.reduce((prev: boolean, next: Item) => {
                return prev && next.correct === next.value;
            }, true)

            if (complete) {
                onComplete();
            }
        }
    }, [onComplete, state])


    return <div className="task-304">

        <StandardInsert>
            <div className="items">
                {
                    state.items.map(item => {
                        return <div className={`item ${item.value === null ? '' : (item.value === item.correct ? 'correct' : 'wrong')}`} key={item.id}>
                            <div className="item-img">
                                <img src={item.itemSrc} alt="" />
                            </div>
                            <div className="arrow">
                                <img src={ARROW_SRC} alt="" />
                            </div>
                            <div className="bin-img">
                                <img src={item.binSrc} alt="" />
                            </div>

                            <div className="buttons">
                                <div className="img-btn">
                                    <input onClick={() => onClick(item, true)} type="image" src={CORRECT_SRC} alt="" />
                                </div>
                                <div className="img-btn">
                                    <input onClick={() => onClick(item, false)} type="image" src={WRONG_SRC} alt="" />
                                </div>
                            </div>

                        </div>
                    })
                }
            </div>
        </StandardInsert>

        {
            popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
            </Popup>
        }
    </div>
}

export default Task304Widget;
