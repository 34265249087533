import { useMemo } from "react";
import { GeoTask } from "../types";

const geoTasks: GeoTask[] = [
    {
        id: '0',
        name: 'Papier',
        type: 'trash',
        image: '/assets/geo/trash/papier.png',
        intro: 'Brawo! Znalazłeś pierwszy odpad porzucony w lesie! Czas wyrzucić go do odpowiedniego kosza. Czy wiesz, że do kosza na makulaturę możemy wyrzucać tylko czysty, niezabrudzony papier? Ten tłusty, czy mokry jak np. papier po maśle nie nadaje się do recyklingu.',
        outro: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'papier'
    },
    {
        id: '1',
        name: 'baterie',
        type: 'trash',
        image: '/assets/geo/trash/baterie.png',
        intro: 'Baterie w lesie?! Dobrze, że już są w Twoich rękach. Wyrzuć je do odpowiedniego pojemnika np. w szkole lub w sklepie, by nie zatruły zwierząt, gleby i wody. Baterie zawierają metale ciężkie, które wyjątkowo mocno szkodzą przyrodzie. Domyślasz się gdzie powinieneś je wyrzucić?',
        outro: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'baterie'
    },
    {
        id: '2',
        name: 'lodówka',
        type: 'trash',
        image: '/assets/geo/trash/lodowka.png',
        intro: 'Czy ja dobrze widzę? To stara lodówka? Nie do wiary, że ktoś wyrzucił ją do lasu! Gdy będziesz spacerował po lesie i znajdziesz śmieci, z którymi nie dasz sobie sam rady, daj znać odpowiednim służbom zarządzającym terenem lub służbom właściwym do ścigania wykroczeń - jak straż gminna, straż miejska, czy policja. A czy na co dzień wiesz, gdzie taki odpad powinien być wyrzucony? Spróbuj dopasować go do odpowiedniego miejsca.',
        outro: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'pszok'
    },
    {
        id: '3',
        name: 'puszka',
        type: 'trash',
        image: '/assets/geo/trash/puszka.png',
        intro: 'Masz ją, świetnie! Puszki po napojach są często znajdowanym odpadem na łonie przyrody. Pamiętaj, skoro dałeś radę przynieść je pełne do lasu, tym bardziej możesz zabrać je ze sobą puste, by następnie wyrzucić odpad do odpowiedniego pojemnika. Tylko czy wiesz do którego?',
        outro: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'tworzywa'
    },
    {
        id: '4',
        name: 'Zadanie specjalne',
        type: 'animal',
        image: '/assets/geo/trash/ryjowka.png',
        trashWidgetImage: '/assets/geo/trash/ryjowka-2.png',
        intro: 'Ratunku! Ktoś porzucił plastikową butelkę w lesie, a teraz nie mogę z niej wyjść! Dobrze, że mnie znalazłeś! Poklikaj w butelkę tak by mnie nakierować na wyjście. Potrząśnij butelką, poruszając telefonem góra - dół i pomóż mi wydostać  się na zewnątrz. Aby butelka  nie stanowiła już zagrożenia, wyrzuć ją do odpowiedniego pojemnika. Segregując na co dzień, koniecznie pamiętaj o jej zgnieceniu!',
        outro: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'tworzywa'
    },
    {
        id: '5',
        name: 'butelka',
        type: 'trash',
        image: '/assets/geo/trash/butelka.png',
        intro: 'Szkło ma właściwości skupiające wiązki światła – co sprawia, że porzucone w lesie, może doprowadzić do pożaru. Potłuczone szkło rani zwierzęta, a szklane butelki stanowią niebezpieczną pułapkę dla najmniejszych mieszkańców lasu. Tak jak plastikowa butelka, z której uwolniłeś ryjówkę! Wrzuć butelkę do odpowiedniego pojemnika, aby mogła zostać ponownie przetworzona.',
        outro: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'szklo'
    },
    {
        id: '6',
        name: 'papierosy',
        type: 'trash',
        image: '/assets/geo/trash/papierosy.png',
        intro: 'Od niedopałka może spłonąć las, a zwierzęta poszukujące świeżych pędów roślin mogą zjadać przypadkowo porzucone pety, co prowadzi do ich zatrucia. Zwróć uwagę dorosłym, by nie wyrzucali niedopałków na ziemię, a do kosza! Tylko czy wiesz którego?',
        outro: 'odpady do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'zmieszane'
    },
    {
        id: '7',
        name: 'sok',
        type: 'trash',
        image: '/assets/geo/trash/sok.png',
        intro: 'Kartony po soku, tak jak i po mleku, należą do odpadów wielomateriałowych. W ich wypadku papier jest powleczony woskiem lub plastikiem oraz aluminium. Opakowania te nie mogą zostać poddane recyklingowi razem z makulaturą, a pozostałe w nich resztki napojów stanowią zagrożenie zabrudzenia papieru, dlatego wyrzuć je do… ',
        outro: 'odpady do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'tworzywa'
    },
    {
        id: '8',
        name: 'szklanka',
        type: 'trash',
        image: '/assets/geo/trash/szklanka.png',
        intro: 'Wiesz, że szkło może rozkładać się nawet ponad 4000 lat? Dobrze, że znalazłeś tę szklankę. Pamiętaj tylko, że do pojemnika na szkło trafiają TYLKO opakowania, tj. słoiki, słoiczki po kosmetykach, butelki. Naczynia, z których pijemy są wykonane z innego rodzaju szkła. Wiesz, gdzie powinieneś je wyrzucić?',
        outro: 'odpad do odpowiedniego posegregowania! Ruszaj dalej!',
        correct: 'zmieszane'
    },
    {
        id: '9',
        name: 'śmieci',
        type: 'trash',
        image: '/assets/geo/trash/smieci.png',
        intro: 'Znalazłeś całe skupisko różnorodnych, wyrzuconych do lasu śmieci. Ale czy wiesz, że powinny trafić do tego samego pojemnika? Mokra chusteczka, paragon na papierze termicznym oraz stłuczone lusterko, nie nadają się do ponownego recyklingu. W takim razie, czy już wiesz gdzie powinny trafić?',
        outro: 'zmieszane',
        correct: 'zmieszane'
    },
];

export default function useGeoTask(id: string) {
    return useMemo(() => {
        return geoTasks.find(gt => gt.id === id);
    }, [id]);
}
