import CenterBox from "../../../layout/center-box";
import './style.scss';

type Props = {}

const PermissionDeniedPopup: React.FC<Props> = () => {
    return <CenterBox className="geo-error">
        <p>Potrzebujemy dostepu do geolokalizacji na twoim urzadzeniu!</p>
        <p>Odblokuj dostęp do geolokalizacji dla naszej strony w swojej przeglądarce i sprubuj ponownie</p>

        <button className="button" onClick={() => {
            document.location.reload();
        }}>Od początku</button>
    </CenterBox>
}

export default PermissionDeniedPopup;
